import { styled } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports -- this is the only place we import from @mui/material
import type { TooltipProps } from "@mui/material/Tooltip";
// eslint-disable-next-line no-restricted-imports -- this is the only place we import from @mui/material
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { theme } from "@polifonia/theme";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    {...(!className ? {} : { classes: { popper: className } })}
  />
))(({ theme: muiTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 12,
    backgroundColor: theme.colors.white,
    color: theme.colors.staticFgBody,
    boxShadow: muiTheme.shadows[0],
    borderRadius: theme.shape.borderRadius,
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.white,
  },
}));

export { Tooltip, type TooltipProps };
