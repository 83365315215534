const httpsUrlWithOptionalWildcardRegex =
  /^https:\/\/(\*\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

const isValidDomain = Object.assign(
  function isValidURL(url: string): boolean {
    return httpsUrlWithOptionalWildcardRegex.test(url);
  },
  {
    regex: httpsUrlWithOptionalWildcardRegex,
  },
);

export { isValidDomain };
