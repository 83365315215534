import { Stack } from "@mui/material";
import { theme } from "@polifonia/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { Typography } from "@/components/Typography";

interface WccsAddLimitModalProps {
  open: boolean;
  onClose: () => void;
}

export const WccsAddLimitModal: FC<WccsAddLimitModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleOpenNewRequestClick = () => {
    window.open(t("newRequest.link"), "_blank");
  };

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={t("channels.addModal.title")}
      footer={
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="primary" onClick={handleOpenNewRequestClick}>
            {t("channels.addModal.footer.contactUs")}
          </Button>
        </Stack>
      }
    >
      <Typography variant="h3" color={theme.colors.staticFgBody}>
        {t("channels.addModal.body.channel.wccs.limit.description")}
      </Typography>
    </Modal>
  );
};
