import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { theme } from "@polifonia/theme";
import useSwitch from "@react-hook/switch";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";
import { defineStyles } from "@/internal/emotion";

const styles = defineStyles({
  outer: css({
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: theme.colors.neutral010,
    gap: "12px",
  }),
  input: css({
    backgroundColor: theme.colors.white,
  }),
  hidden: css({
    visibility: "hidden",
  }),
  icon: css({
    fontSize: "16px",
  }),
});

interface FormFieldValues {
  text: string;
}

interface ChannelNameCardProps {
  value: string;
  onSubmit: (value: string) => Promise<void> | void;
  disabled?: boolean;
  maxLength?: number;
  icon?: React.ReactNode;
  description?: React.ReactNode;
}

const ChannelNameCard: React.FC<ChannelNameCardProps> = ({
  value,
  onSubmit,
  disabled = false,
  maxLength = 255,
  icon,
  description,
}) => {
  const { t } = useTranslation();
  const [isEdit, toggleIsEdit] = useSwitch(false);
  const { control, handleSubmit, reset, setValue } = useForm<FormFieldValues>({
    mode: "onChange",
    defaultValues: {
      text: value,
    },
  });

  const onSubmitHandler: SubmitHandler<FormFieldValues> = useHandler(
    async (values) => {
      const text = values.text.trim();
      if (value === text) {
        setValue("text", value);
        toggleIsEdit.off();
        return;
      }
      onSubmit(text);
      toggleIsEdit.off();
    },
  );

  const onError = useHandler(() => {
    reset();
    toggleIsEdit.off();
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler, onError)}>
      <div css={styles.outer}>
        {icon}
        <Stack width="200px" direction="column" gap="4px">
          {!isEdit ? (
            <Typography
              variant="body"
              color={theme.colors.neutral100}
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {value}
            </Typography>
          ) : (
            <Controller
              name="text"
              control={control}
              rules={{
                validate: (value) => {
                  if (value.trim() === "") {
                    return t("validation.pleaseInputName");
                  }
                  return true;
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  css={styles.input}
                  autoFocus
                  onBlur={(e) => {
                    e.target.blur();
                    handleSubmit(onSubmitHandler, onError)();
                  }}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                  slotProps={{
                    htmlInput: {
                      maxLength,
                    },
                  }}
                />
              )}
            />
          )}
          {description}
        </Stack>
        <IconButton
          size="small"
          disabled={disabled}
          onClick={toggleIsEdit.on}
          css={css([isEdit && styles.hidden])}
        >
          <MotifIcon un-i-motif="edit" css={styles.icon} />
        </IconButton>
      </div>
    </form>
  );
};

export { ChannelNameCard };
