import { createQueriesContext } from "@polifonia/utils/react-query/createQueriesContext";
import { getRouteApi } from "@tanstack/react-router";

import { interlude } from "@/interlude";

const route = getRouteApi("/channels/$type/$id");

const useChannelQueries = () => {
  const { id } = route.useParams();
  const channelQuery = interlude.channel.useGetById({
    params: {
      channelId: id,
    },
    suspense: true,
    useErrorBoundary: true,
  });
  return {
    channel: channelQuery,
  };
};

const ChannelQueriesContext =
  createQueriesContext<ReturnType<typeof useChannelQueries>>();

export { ChannelQueriesContext, useChannelQueries };
