import type {
  ZodiosEndpointDefinitions,
  ZodiosInstance,
  ZodiosOptions,
} from "@zodios/core";
import { Zodios } from "@zodios/core";
import type { Narrow } from "@zodios/core/lib/utils.types";
import { objectEntries, objectFromEntries } from "tsafe";

/**
 * @example
 *
 * ```ts
 * const api = generate({
 *   user: userApi,
 *   tag: tagApi,
 *   // ...
 * }, {
 *   axiosInstance,
 * });
 *
 * const response = await api.user.getById( ... );
 * ```
 *
 * @param api - The api record where key is the name of the api and value is the
 * api endpoint definitions.
 * @param key - api[key], the api endpoint definitions.
 * @param zodiosOptions - The options to pass to `Zodios`.
 */
export function generateApi<
  ApiRecord extends Record<string, ZodiosEndpointDefinitions>,
>({
  api,
  zodiosOptions,
}: {
  api: {
    [Key in keyof ApiRecord]: Narrow<ApiRecord[Key]>;
  };
  zodiosOptions?: ZodiosOptions;
}): {
  [Key in keyof ApiRecord]: ZodiosInstance<Narrow<ApiRecord[Key]>>;
} {
  const entries: Array<[string, ZodiosInstance<ZodiosEndpointDefinitions>]> =
    objectEntries(api).flatMap(([name, api]) => {
      return [
        [
          name as string,
          new Zodios(api as ZodiosEndpointDefinitions, zodiosOptions),
        ],
      ];
    });

  const object: {
    [Key in keyof ApiRecord]: ZodiosInstance<Narrow<ApiRecord[Key]>>;
  } = objectFromEntries(entries) as {
    [Key in keyof ApiRecord]: ZodiosInstance<Narrow<ApiRecord[Key]>>;
  };
  return object;
}
