import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "organizationSetting",
    method: "get",
    path: "/api/v1/organizations/setting",
    response: z.object({
      id: z.number().int().positive(),
      wccsAvailableWccsChannelNum: z.number(),
      wccsWebChatModuleEnabled: z.boolean(),
      wccsWebFootprintTrackingEnabled: z.boolean(),
    }),
  },
]);

export { api };
