import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import type {
  QueryClient,
  QueryFilters,
  QueryKey,
} from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "es-toolkit";

class Task {
  readonly queryFilters: QueryFilters;
  readonly callbacks: Array<{
    resolve: () => void;
  }> = [];
  constructor(queryFilters: QueryFilters, callbacks?: Task["callbacks"]) {
    this.queryFilters = queryFilters;
    callbacks?.forEach((callback) => this.addCallback(callback));
  }
  addCallback(callback: { resolve: () => void }) {
    this.callbacks.push(callback);
  }
}

const queryClientToTaskManagerWeakMap = new WeakMap<QueryClient, TaskManager>();

class TaskManager {
  readonly tasks: Array<Task> = [];
  getTask(queryFilters: QueryFilters) {
    return this.tasks.find((task) => isEqual(task.queryFilters, queryFilters));
  }
  addTask(queryFilters: QueryFilters, resolve: () => void) {
    const task = this.getTask(queryFilters);
    if (task) {
      task.addCallback({ resolve });
    } else {
      this.tasks.push(
        new Task(queryFilters, [
          {
            resolve,
          },
        ]),
      );
    }
  }
  removeTask(task: Task) {
    const taskIndex = this.tasks.indexOf(task);
    this.tasks.splice(taskIndex, 1);
  }
}

function getTaskManager(queryClient: QueryClient) {
  let taskManager = queryClientToTaskManagerWeakMap.get(queryClient);
  if (!taskManager) {
    taskManager = new TaskManager();
    queryClientToTaskManagerWeakMap.set(queryClient, taskManager);
  }
  return taskManager;
}

const queryClientToFetchingListWeakMap = new WeakMap<
  QueryClient,
  FetchingList
>();

class FetchingList {
  readonly fetchingList: Array<QueryFilters> = [];
  constructor() {
    this.fetchingList = [];
  }
  add(queryFilters: QueryFilters) {
    this.fetchingList.push(queryFilters);
  }
  remove(queryFilters: QueryFilters) {
    const index = this.fetchingList.indexOf(queryFilters);
    if (index === -1) return;
    this.fetchingList.splice(index, 1);
  }
  get(queryFilters: QueryFilters) {
    return this.fetchingList.find((fetchingKey) =>
      isEqual(fetchingKey, queryFilters),
    );
  }
}

function getFetchingList(queryClient: QueryClient) {
  let fetchingList = queryClientToFetchingListWeakMap.get(queryClient);
  if (!fetchingList) {
    fetchingList = new FetchingList();
    queryClientToFetchingListWeakMap.set(queryClient, fetchingList);
  }
  return fetchingList;
}

/**
 * Minimum delay to avoid continuous fetching.
 */
const minDelay = 1000;

/**
 * Framework-agnostic function.
 */
async function safeInvalidateQuery(
  queryClient: QueryClient,
  queryFilters: QueryFilters,
  callbacks?: Task["callbacks"],
): Promise<void> {
  const taskManager = getTaskManager(queryClient);
  const fetchingList = getFetchingList(queryClient);
  if (fetchingList.get(queryFilters)) {
    return new Promise<void>((resolve) => {
      taskManager.addTask(queryFilters, resolve);
    });
  }
  fetchingList.add(queryFilters);
  await Promise.allSettled([
    queryClient.invalidateQueries(queryFilters),
    delay(minDelay),
  ]);
  fetchingList.remove(queryFilters);
  callbacks?.forEach(({ resolve }) => resolve());
  const nextTask = taskManager.getTask(queryFilters);
  if (nextTask) {
    taskManager.removeTask(nextTask);
    safeInvalidateQuery(queryClient, queryFilters, nextTask.callbacks);
  }
}

/**
 * Get a function that will invalidate a query, but will wait if the query is
 * currently fetching.
 *
 * Notice that the query queryFilters must be exact.
 *
 * @example
 *
 * ```ts
 * const queryKey: QueryFilters = ['user', { id: 1 }];
 * const query = useQuery(queryKey, () => fetchUser(1));
 * const safeInvalidateQuery = useSafeInvalidateQuery();
 * useSomeListener(() => {
 *   safeInvalidateQuery(queryKey);
 * });
 * ```
 */
function useSafeInvalidateQuery() {
  const queryClient = useQueryClient();
  const reactSafeInvalidateQuery = useHandler(
    async function reactSafeInvalidateQuery(
      key: QueryKey,
      callbacks?: Task["callbacks"],
    ): Promise<void> {
      const queryFilters: QueryFilters = {
        queryKey: key,
        exact: true,
      };
      return await safeInvalidateQuery(queryClient, queryFilters, callbacks);
    },
  );
  return reactSafeInvalidateQuery;
}

export { safeInvalidateQuery, useSafeInvalidateQuery };
