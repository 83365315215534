import { css } from "@emotion/react";
import { Chip } from "@mui/material";
import { theme } from "@polifonia/theme";
import type { FC } from "react";

import { Typography } from "@/components/Typography";

export interface BadgeProps {
  variant?:
    | "superior"
    | "plain"
    | "primary"
    | "positive"
    | "attentive"
    | "negative"
    | "disable";
  children: React.ReactNode;
}

const badgeVariantMap: Record<
  NonNullable<BadgeProps["variant"]>,
  { bgColor: string; fgColor: string }
> = {
  superior: {
    bgColor: theme.colors.staticBgSuperior,
    fgColor: theme.colors.staticFgSuperior,
  },
  plain: {
    bgColor: theme.colors.staticBgPlain,
    fgColor: theme.colors.staticFgPlain,
  },
  primary: {
    bgColor: theme.colors.staticBgPrimary,
    fgColor: theme.colors.staticFgPrimary,
  },
  positive: {
    bgColor: theme.colors.staticBgPositive,
    fgColor: theme.colors.staticFgPositive,
  },
  attentive: {
    bgColor: theme.colors.staticBgAttentive,
    fgColor: theme.colors.staticFgAttentive,
  },
  negative: {
    bgColor: theme.colors.staticBgNegative,
    fgColor: theme.colors.staticFgNegative,
  },
  disable: {
    bgColor: theme.colors.staticBgDisable,
    fgColor: theme.colors.staticFgDisable,
  },
} as const;

export const Badge: FC<BadgeProps> = ({ variant = "superior", children }) => {
  return (
    <Chip
      label={
        <Typography
          variant="note"
          fontWeight={500}
          css={css({
            color: badgeVariantMap[variant].fgColor,
            display: "flex",
            alignItems: "center",
          })}
        >
          {children}
        </Typography>
      }
      sx={{
        height: "20px",
        backgroundColor: badgeVariantMap[variant].bgColor,
      }}
    />
  );
};
