// TODO: move this to @chatbotgang/etude

import { shallow } from "@polifonia/utils/zustand/shallow";
import { useState } from "react";
import { createWithEqualityFn } from "zustand/traditional";
/**
 * A map used to store the relationship between an `AbortSignal` and an
 * `AbortController`. This enables us to retrieve the `AbortController`
 * associated with an `AbortSignal`.
 */
const signalToAbortControllerMap = new WeakMap<AbortSignal, AbortController>();

function getNewSignal() {
  const abortController = new AbortController();
  signalToAbortControllerMap.set(abortController.signal, abortController);
  return abortController.signal;
}

/**
 * To create an `AbortController` store, it will generate a new
 * `AbortController` when `abort()` is invoked.
 */
function createAbortControllerStore() {
  const useStore = createWithEqualityFn<{
    signal: AbortSignal;
    abort: () => void;
  }>()(
    (set, get) => ({
      signal: getNewSignal(),
      abort: () => {
        const abortController = signalToAbortControllerMap.get(get().signal);
        if (!abortController) return;

        abortController.abort();
        set((state) => ({
          ...state,
          signal: getNewSignal(),
        }));
      },
    }),
    shallow,
  );
  return useStore;
}

/**
 * To create an `AbortController` store, it will generate a new
 * `AbortController` when `abort()` is invoked.
 *
 * @example
 *
 * ```ts
 * const useAbortControllerStore = useUseAbortControllerStore();
 * const mutation = useMutation(...);
 *
 * useEffect(() => {
 *   // ...
 *   useAbortControllerStore.getState().abort();
 *   mutation.mutate({ signal: useAbortControllerStore.getState().signal });
 * }, [ ... ]);
 * ```
 */
function useUseAbortControllerStore() {
  const [useAbortControllerStore] = useState(createAbortControllerStore);
  return useAbortControllerStore;
}

export { createAbortControllerStore, useUseAbortControllerStore };
