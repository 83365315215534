import { styled } from "@mui/material/styles";
import type { SwitchProps } from "@mui/material/Switch";
import BaseSwitch from "@mui/material/Switch";
import { theme } from "@polifonia/theme";

const Switch = styled((props: SwitchProps) => (
  <BaseSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme: muiTheme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: theme.colors.white,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.colors.blue050,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
        backgroundColor: theme.colors.neutral030,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.colors.blue050,
      border: `6px solid ${theme.colors.white}`,
    },
    "&.Mui-disabled": {
      "& .MuiSwitch-thumb": {
        color: theme.colors.white,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.colors.neutral020,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    boxShadow: "0px 2px 4px 0px rgba(0, 35, 11, 0.2)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    backgroundColor: theme.colors.neutral040,
    opacity: 1,
    transition: muiTheme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export { Switch };
