import { getRouteApi } from "@tanstack/react-router";
import type { FC } from "react";

import { WccsDetailPage } from "@/pages/channels/pages/detail/wccs";
import { WhatsappDetailPage } from "@/pages/channels/pages/detail/whatsapp";

const route = getRouteApi("/channels/$type/$id");

export const ChannelDetailPage: FC = () => {
  const { type } = route.useParams();

  if (type === "wccs") {
    return <WccsDetailPage />;
  }

  if (type === "whatsapp") {
    return <WhatsappDetailPage />;
  }

  return null;
};
