import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { theme } from "@polifonia/theme";
import { fakeT } from "@polifonia/utils/react/fakeT";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components/Badge";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { Switch } from "@/components/Switch";
import { Typography } from "@/components/Typography";
import { interlude } from "@/interlude";
import { defineStyles } from "@/internal/emotion";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";
import bindingImg from "@/pages/channels/pages/detail/wccs/image/binding.png";
import chatImg from "@/pages/channels/pages/detail/wccs/image/chat.png";
import trackingImg from "@/pages/channels/pages/detail/wccs/image/tracking.png";

const styles = defineStyles({
  featureCard: css({
    display: "flex",
    gap: "16px",
    border: `1px solid ${theme.colors.neutral030}`,
    borderRadius: "12px",
    paddingLeft: "24px",
    overflow: "hidden",
  }),
  featureContent: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "20px 0",
  }),
  img: css({
    objectFit: "cover",
    width: "210px",
  }),
});

const Layout: React.FC<{
  control: React.ReactNode;
  content: React.ReactNode;
  image: React.ReactNode;
}> = ({ control, content, image }) => {
  return (
    <div css={styles.featureCard}>
      <Stack direction="row" gap={3} flex={1}>
        <Stack alignItems="center" justifyContent="center">
          {control}
        </Stack>
        <div css={styles.featureContent}>{content}</div>
      </Stack>
      {image}
    </div>
  );
};

interface ModuleSwitchProps {
  field: "webChatModuleEnabled" | "webTrackingEnabled";
}

const t = fakeT;
const moduleTranslationMap: Record<
  ModuleSwitchProps["field"],
  Record<"enabled" | "disabled", string>
> = {
  webChatModuleEnabled: {
    enabled: t("page.channel.tools.chat.enabled"),
    disabled: t("page.channel.tools.chat.disabled"),
  },
  webTrackingEnabled: {
    enabled: t("page.channel.tools.tracking.enabled"),
    disabled: t("page.channel.tools.tracking.disabled"),
  },
};

const ModuleSwitch: React.FC<ModuleSwitchProps> = ({ field }) => {
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const mutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess(result) {
        if (result.type !== "wccs") return;
        const isEnabled = result.channelInformation[field];
        const messageKey =
          moduleTranslationMap[field][isEnabled ? "enabled" : "disabled"];
        enqueueSnackbar(t(messageKey), {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );

  const checked = useMemo(() => {
    if (channel.type !== "wccs") return false;
    return channel.channelInformation[field];
  }, [channel.type, channel.channelInformation, field]);

  const handleChange = useHandler<
    React.ComponentProps<typeof Switch>["onChange"]
  >(function handleChange(_, enabled) {
    mutation.mutate({
      channelInformation: {
        [field]: enabled,
      },
    });
  });

  return (
    <Switch
      disabled={mutation.isLoading}
      checked={checked}
      onChange={handleChange}
    />
  );
};

const ChatModuleSwitch: React.FC = () => (
  <ModuleSwitch field="webChatModuleEnabled" />
);

const TrackingModuleSwitch: React.FC = () => (
  <ModuleSwitch field="webTrackingEnabled" />
);

export const Tools: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={2}>
      <Stack gap={3}>
        <Typography variant="h3" fontWeight={500}>
          {t("page.channel.tabs.tools")}
        </Typography>
        <Layout
          control={<ChatModuleSwitch />}
          content={
            <>
              <Stack gap={0.5}>
                <Typography
                  variant="h3"
                  color={theme.colors.staticFgTitle}
                  fontWeight={500}
                >
                  {t("page.channel.tools.chat.title")}
                </Typography>
                <Typography>{t("page.channel.tools.chat.desc")}</Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                <ExternalLink
                  href={t("page.channel.tools.chat.link")}
                  trailingIcon={true}
                >
                  {t("common.learnMore")}
                </ExternalLink>

                <ExternalLink
                  css={css({ flexDirection: "row-reverse" })}
                  href={t("page.channel.tools.chat.notifyMe.link")}
                  trailingIcon={<MotifIcon un-i-motif="bell" />}
                >
                  {t("page.channel.tools.chat.notifyMe.label")}
                </ExternalLink>
              </Stack>
            </>
          }
          image={<img src={chatImg} css={styles.img} />}
        />
        <Layout
          control={<TrackingModuleSwitch />}
          content={
            <>
              <Stack gap={0.5}>
                <Typography
                  variant="h3"
                  color={theme.colors.staticFgTitle}
                  fontWeight={500}
                >
                  {t("page.channel.tools.tracking.title")}
                </Typography>
                <Typography>{t("page.channel.tools.chat.desc")}</Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                <ExternalLink href={t("page.channel.tools.tracking.link")}>
                  {t("common.learnMore")}
                </ExternalLink>
              </Stack>
            </>
          }
          image={<img src={trackingImg} css={styles.img} />}
        />
        <Layout
          control={<Switch disabled />}
          content={
            <>
              <Stack gap={0.5}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography
                    variant="h3"
                    color={theme.colors.staticFgTitle}
                    fontWeight={500}
                  >
                    {t("page.channel.tools.binding.title")}
                  </Typography>
                  <Badge variant="primary">
                    {t("page.channel.tools.upComing")}
                  </Badge>
                </Stack>
                <Typography>{t("page.channel.tools.binding.desc")}</Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                <ExternalLink href={t("page.channel.tools.binding.link")}>
                  {t("common.learnMore")}
                </ExternalLink>
              </Stack>
            </>
          }
          image={<img src={bindingImg} css={styles.img} />}
        />
      </Stack>
    </Stack>
  );
};
