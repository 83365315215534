import {
  ChannelQueriesContext,
  useChannelQueries,
} from "@/pages/channels/pages/detail/channelQueriesContext/context";

const ChannelQueriesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const queries = useChannelQueries();
  return (
    <ChannelQueriesContext.Provider queries={queries}>
      {children}
    </ChannelQueriesContext.Provider>
  );
};

export { ChannelQueriesContextProvider };
