/**
 * Steps of adding new locales:
 * 1. Add other language setting in `availableLocales`
 * 2. Go to `LangsLocalesUtils.ts` and add new strategy in `langStrategies`
 * 3. Use strategy in `formatLangCode`, and `makeFallbackLangs`
 */
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import useEnhancedEffect from "@chatbotgang/etude/react/useEnhancedEffect";
import { DEVELOPMENT_MODE, ENV, ENV_TEST, IS_STORYBOOK } from "@polifonia/env";
import type { i18n as i18nType } from "i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";

import {
  GLOBAL_SEARCH_PARAM_I18NEXT,
  LOCAL_STORAGE_I18NEXT,
} from "@/appConstant";
import { availableLocales } from "@/config/availableLocales";
import { useFeatureFlag, withFeatureFlagWrapper } from "@/features/featureFlag";
import { langStrategies } from "@/features/i18n/LangsLocalesUtils";
import { logError } from "@/features/logger";
import { firebaseTools as firebase } from "@/lib/firebase";

export type AvailableLangs = (typeof availableLocales)[number]["code"];
export type AvailableLangsDisplay =
  (typeof availableLocales)[number]["displayName"];

/**
 * Unify lang code to our accepted format in `AvailableLangs`, resource lang code might be from navigator or others
 * @param lang - language code
 * @returns formatted lang code
 */
export const formatLangCode = (lang: string) => {
  const strategies = [
    langStrategies.isZHLang.bind<
      undefined,
      [string, "zh-hant"],
      [],
      "zh-hant" | undefined
    >(this, lang, "zh-hant"),
    langStrategies.isENLang.bind<
      undefined,
      [string, "en"],
      [],
      "en" | undefined
    >(this, lang, "en"),
    langStrategies.isTHLang.bind<
      undefined,
      [string, "th"],
      [],
      "th" | undefined
    >(this, lang, "th"),
    langStrategies.isJALang.bind<
      undefined,
      [string, "ja"],
      [],
      "ja" | undefined
    >(this, lang, "ja"),
    langStrategies.default.bind<undefined, [string, "en"], [], "en">(
      this,
      lang,
      "en",
    ),
  ];
  let formattedCode;

  for (let index = 0; index < strategies.length; index++) {
    const func = strategies[index];
    formattedCode = func?.();
    if (formattedCode) {
      break;
    }
  }

  return formattedCode ?? "en";
};

/**
 * Locales offer more granular control of localization features than simple language codes
 * If we only pass a language code to the Intl.NumberFormat constructor it might guess wrong
 * This is particularly the case with `zh`
 * @param languageCode - standard language code in our system
 * @returns string - a specific locale code
 */
export const getLocaleFromLanguageCode = (lang: string) => {
  const langCode = formatLangCode(lang);

  switch (langCode) {
    case "zh-hant":
      return "zh-TW";
    case "ja":
      return "ja-JP";
    case "th":
      return "th-TH";
    case "en":
      return "en-US";
    default:
      langCode satisfies never;
      return "en-US";
  }
};

/**
 * Generate `fallbackLng` for i18next fallbackLng
 * @param lang -language code
 */
const makeFallbackLangs = (lang: string | undefined) => {
  if (typeof lang === "undefined") {
    return ["en"] as const;
  }

  const strategies = [
    langStrategies.isZHLang.bind<
      undefined,
      [string, ["zh-hant", "en"]],
      [],
      ["zh-hant", "en"] | undefined
    >(this, lang, ["zh-hant", "en"]),
    langStrategies.isENLang.bind<
      undefined,
      [string, ["en"]],
      [],
      ["en"] | undefined
    >(this, lang, ["en"]),
    langStrategies.isTHLang.bind<
      undefined,
      [string, ["th", "en"]],
      [],
      ["th", "en"] | undefined
    >(this, lang, ["th", "en"]),
    langStrategies.isJALang.bind<
      undefined,
      [string, ["ja", "en"]],
      [],
      ["ja", "en"] | undefined
    >(this, lang, ["ja", "en"]),
    langStrategies.default.bind<
      undefined,
      [string, ["en"]],
      [],
      ["en"] | undefined
    >(this, lang, ["en"]),
  ];
  let fallbackLng;

  for (let index = 0; index < strategies.length; index++) {
    const func = strategies[index];
    fallbackLng = func?.();
    if (fallbackLng) {
      break;
    }
  }

  return fallbackLng ?? ["en"];
};

const setHtmlLang = (lang: string): void => {
  document.documentElement.setAttribute("lang", lang);
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: DEVELOPMENT_MODE && !ENV_TEST,
      detection: {
        order: ["querystring", "localStorage", "navigator"],
        lookupQuerystring: GLOBAL_SEARCH_PARAM_I18NEXT,
        lookupLocalStorage: LOCAL_STORAGE_I18NEXT,
        caches: ["localStorage"],
        convertDetectedLanguage: formatLangCode,
      },
      lowerCaseLng: true, // all lowercase for third-part library / frontend / backend consistency, eg. zh-Hant -> zh-hant
      fallbackLng: makeFallbackLangs,
      backend: {
        backends: [
          ...(IS_STORYBOOK ? [] : [HttpBackend]),
          // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
          resourcesToBackend((language: string) => {
            // Fall back to local translation files
            return import(
              `../../../node_modules/.cache/i18n/${language}/translation.json`
            );
          }),
        ],
        backendOptions: [
          {
            loadPath: async (
              languages: Array<string>,
              namespaces: Array<string>,
            ) => {
              const [lng] = languages;
              const [ns] = namespaces;
              const availableLocaleCodes = availableLocales.map(
                (locale) => locale.code,
              );

              // This prevents fetching additional duplicate locale files
              if (!ns || !lng || !availableLocaleCodes.includes(lng)) {
                return undefined;
              }

              try {
                return await firebase.getDownloadUrl(
                  "locale",
                  `/admin-center/${ENV}/latest/json/${lng}/${ns}.json`,
                );
              } catch (error) {
                logError(error);
              }
            },
          },
        ],
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss,
        format: (value, format) => {
          if (format === "thousandth") return value.toLocaleString();
          return value;
        },
      },
      saveMissing: true,
      missingKeyHandler: (languages, namespace, key) => {
        logError(
          inspectMessage`Missing translation key: ${key} in ${namespace} of ${languages}`,
        );
      },
      missingInterpolationHandler: (text, value) => {
        logError(
          inspectMessage`Missing interpolation value: ${value} in ${text}`,
        );
      },
    },
    () => {
      setHtmlLang(i18n.language);
    },
  );

i18n.on("languageChanged", (lng) => {
  setHtmlLang(lng);
});

export const ApplyI18nFromFeatureFlag = withFeatureFlagWrapper(
  "quickI18n",
  memo(function ApplyI18nFromDevMode() {
    const { i18n } = useTranslation();
    const targetI18n = useFeatureFlag("quickI18n");

    useEnhancedEffect(() => {
      if (!targetI18n) return;
      if (i18n.language === targetI18n) return;
      i18n.changeLanguage(targetI18n);
    }, [i18n, targetI18n]);

    return null;
  }),
);

function loadFont(i18n: i18nType) {
  async function loadFont() {
    import(`./fonts/${i18n.language}.css`);
  }
  i18n.on("languageChanged", loadFont);
  return function destory() {
    i18n.off("languageChanged", loadFont);
  };
}

loadFont(i18n);

export { i18n };
