import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@polifonia/theme";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { useMemo } from "react";

import { NewWindowIcon } from "@/components/Icons/NewWindowIcon";
import { cssOneLine } from "@/internal/emotion";

type ExternalLinkProps = ComponentPropsWithRef<"a"> & {
  href: string;
  trailingIcon?: ReactNode | true;
  noWrap?: boolean;
  disabled?: boolean;
};

type ExternalLinkType = (props: ExternalLinkProps) => ReactNode;

const cssBase = css`
  display: inline-flex;
  gap: 0.25em;
  color: ${theme.colors.blue060};
  align-items: center;

  :not(:hover, :active) {
    text-decoration-line: none;
  }

  &:focus {
    outline: ${theme.colors.blue060} auto 1px;
  }

  &:hover {
    color: ${theme.colors.blue050};
  }

  &:active {
    color: ${theme.colors.blue070};
  }
`;

const cssNoWrap = css`
  ${cssOneLine}
`;

/**
 * This component is under TBD for overflow handling.
 */
const ExternalLink: ExternalLinkType = forwardRef<
  HTMLAnchorElement,
  ExternalLinkProps
>(function ExternalLink(
  { children, trailingIcon, noWrap, disabled, ...props },
  ref,
) {
  const mergedCss = useMemo(
    function mergedCss() {
      return css(cssBase, noWrap && cssNoWrap);
    },
    [noWrap],
  );
  const tabIndex = "tabIndex" in props ? props.tabIndex : 0;
  return (
    <a
      css={mergedCss}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      tabIndex={tabIndex}
      ref={ref}
    >
      <span>{children}</span>
      <span css={css({ display: "inline-flex", fontSize: "1.14em" })}>
        {!trailingIcon ? null : trailingIcon === true ? (
          <NewWindowIcon fontSize="inherit" />
        ) : (
          trailingIcon
        )}
      </span>
    </a>
  );
});

export { ExternalLink };
export type { ExternalLinkProps, ExternalLinkType };
