import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { fakeT } from "@polifonia/utils/react/fakeT";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@/components/Typography";
import type { InterludeTypes } from "@/interlude";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";

const t = fakeT;
const translationMap: Record<InterludeTypes["Channel"]["type"], string> = {
  line: t("page.channel.config.externalId.line.label"),
  whatsapp: t("page.channel.config.externalId.whatsapp.label"),
  wccs: t("page.channel.config.externalId.wccs.label"),
};

export const ExternalChannelId: FC = () => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();

  const channelId = useMemo(
    function getDisplayId() {
      const channelType = channel.type;
      switch (channelType) {
        case "line":
          return channel.externalChannelId;
        case "whatsapp":
          return channel.channelInformation.phoneNumber;
        case "wccs":
          return channel.externalChannelId;
        default:
          channelType satisfies never;
          throw new Error(
            inspectMessage`Unexpected channel type: ${channelType}`,
          );
      }
    },
    [channel.channelInformation, channel.externalChannelId, channel.type],
  );

  return (
    <Typography variant="note">
      {t(translationMap[channel.type], {
        channelId,
      })}
    </Typography>
  );
};
