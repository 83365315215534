import { Stack } from "@mui/material";
import { theme } from "@polifonia/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { Typography } from "@/components/Typography";

interface FailedConnectModalProps {
  open: boolean;
  onClose: () => void;
  onConfrim: () => void;
}

export const FailedConnectModal: FC<FailedConnectModalProps> = ({
  open,
  onClose,
  onConfrim,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={t("page.channel.whatsapp.failedConnect.modal.title")}
      footer={
        <Stack direction="row" justifyContent="flex-end" gap="12px">
          <Button variant="primary" onClick={onConfrim}>
            {t("common.confirm")}
          </Button>
        </Stack>
      }
    >
      <Stack gap="16px">
        <Typography variant="body" color={theme.colors.staticFgBody}>
          {t("page.channel.whatsapp.failedConnect.modal.description")}
        </Typography>
      </Stack>
    </Modal>
  );
};
