import { fc } from "@chatbotgang/etude/react/fc";
import { css } from "@emotion/react";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
// eslint-disable-next-line no-restricted-imports -- this is the only place we import from @mui/material
import type { ModalProps as MuiModalProps } from "@mui/material/Modal";
// eslint-disable-next-line no-restricted-imports -- -- this is the only place we import from @mui/material
import MuiModal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import type { FC } from "react";

import { ExclamationIcon } from "@/components/Modal/ExclamationIcon";
import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";
import { defineStyles } from "@/internal/emotion";

type MuiModalPropsOnClose = NonNullable<MuiModalProps["onClose"]>;

export interface ModalProps extends MuiModalProps {
  handleClose: (
    event: Parameters<MuiModalPropsOnClose>[0],
    reason: Parameters<MuiModalPropsOnClose>[1] | "closeIconClick",
  ) => void;
  width?: number;
  footer?: React.ReactNode;
}

const ExtendedModal = fc<ModalProps>(function Modal({
  open,
  title,
  handleClose = () => {},
  width = 520,
  children,
  footer,
  style = {},
  ...restProps
}) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      style={style}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...restProps}
    >
      <Box
        sx={{
          padding: 0,
          borderRadius: "12px",
          backgroundColor: theme.colors.white,
          width,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"flex-start"}
          sx={{
            minHeight: "60px",
            padding: "12px 24px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            fontWeight={700}
            color={theme.colors.staticFgTitle}
          >
            {title}
          </Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              handleClose(e, "closeIconClick");
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Box
          sx={{
            padding: "0 24px",
            overflowY: "auto",
            minHeight: "68px",
            maxHeight: "75vh",
          }}
        >
          {children}
        </Box>
        {footer ? (
          <Box
            sx={{
              padding: "16px 24px",
            }}
          >
            {footer}
          </Box>
        ) : null}
      </Box>
    </MuiModal>
  );
});

const modalWithIconStyles = defineStyles({
  content: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "32px",
    "& > :first-child": {
      display: "flex",
      width: "min(100%, 110px)",
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: 1,
    },
  }),
  circle: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  }),
  descriptions: css({
    alignSelf: "stretch",
  }),
  success: css({
    backgroundColor: theme.colors.green020,
    color: theme.colors.green060,
    fontSize: "48px",
  }),
});

const Warning = (() => {
  const styles = defineStyles({
    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;

      > :first-child {
        display: flex;
        width: min(100%, 110px);
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;

        > * {
          overflow: hidden;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
        }
      }
    `,
  });
  const Warning: FC<ModalProps> = (props) => {
    return (
      <ExtendedModal {...props}>
        <div css={styles.content}>
          <div>
            <ExclamationIcon
              css={css`
                display: flex;
                width: 110px;
                height: 110px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: ${theme.colors.yellow020};
                color: ${theme.colors.yellow060};
                font-size: 65px;
              `}
            />
          </div>
          <div css={modalWithIconStyles.descriptions}>{props.children}</div>
        </div>
      </ExtendedModal>
    );
  };
  return Warning;
})();

const Success: FC<ModalProps> = (props) => {
  return (
    <ExtendedModal {...props}>
      <div css={modalWithIconStyles.content}>
        <div
          css={css([modalWithIconStyles.circle, modalWithIconStyles.success])}
        >
          <MotifIcon un-i-motif="check" />
        </div>
        <div css={modalWithIconStyles.descriptions}>{props.children}</div>
      </div>
    </ExtendedModal>
  );
};

const Modal = Object.assign(ExtendedModal, { Warning, Success });

export { Modal };
