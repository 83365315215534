import { createFbSdk } from "@chatbotgang/fb-sdk";
import { FB_APP_ID, FB_CURSOR_BASED_PAGINATION_LIMIT } from "@polifonia/env";

import { logError } from "@/features/logger";

export const fbSdk = createFbSdk({
  logError,
  appId: FB_APP_ID,
  cursorBasedPaginationLimit: FB_CURSOR_BASED_PAGINATION_LIMIT,
});
