import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Alert, IconButton, Stack, Tab, Tabs, TextField } from "@mui/material";
import { theme } from "@polifonia/theme";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Badge } from "@/components/Badge";
import { Button } from "@/components/Button";
import { CopyButton } from "@/components/Button/CopyButton";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";
import { Trans } from "@/features/i18n/Trans";
import type { InterludeTypes } from "@/interlude";
import { interlude } from "@/interlude";
import { defineStyles } from "@/internal/emotion";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";
import { isValidDomain } from "@/pages/channels/pages/detail/wccs/utils/isValidDomain";

const BadgeList = styled.ol({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  flexDirection: "column",
  gap: 24,
});

const BadgeItem: React.FC<{
  index: number;
  title: React.ReactNode;
  content: React.ReactNode;
}> = ({ index, title, content }) => {
  return (
    <Stack direction="row" gap="8px">
      <Badge variant="plain">{index}</Badge>
      <Stack direction="column" gap="8px" maxWidth="696px" width="100%">
        <Typography
          variant="h3"
          color={theme.colors.staticFgTitle}
          fontWeight={500}
        >
          {title}
        </Typography>
        {content}
      </Stack>
    </Stack>
  );
};

const DeleteDomainButton: React.FC<{
  domainId: InterludeTypes["WccsChannel"]["channelInformation"]["domains"][number]["id"];
}> = ({ domainId }) => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const deleteMutation = interlude.channel.useDeleteChannelDomain(
    {
      params: {
        channelId: channel.id,
        domainId,
      },
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t("common.updatedSuccessfully"), {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );
  const handleDeleteDomain = useHandler(function handleDeleteDomain() {
    deleteMutation.mutate(undefined);
  });
  return (
    <IconButton size="small" onClick={handleDeleteDomain}>
      <MotifIcon un-i-motif="bin" />
    </IconButton>
  );
};

interface FormFieldValues {
  url: string;
}

// todo: add upsert domain logic, if domain already exists, update it, otherwise create it
const DomainItemForm: React.FC<{ domainId?: number; url: string }> = ({
  domainId,
  url,
}) => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useForm<FormFieldValues>({
    mode: "onChange",
    defaultValues: {
      url,
    },
    values: {
      url,
    },
  });
  const mutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess() {
        enqueueSnackbar(
          <Trans i18nKey="page.channel.install.domain.add.success" />,
          {
            variant: "success",
          },
        );
        reset();
      },
    },
  );
  const onSubmitHandler = useHandler<SubmitHandler<FormFieldValues>>(
    ({ url }) => {
      mutation.mutate({
        channelInformation: {
          domains: [
            {
              url: url,
            },
          ],
        },
      });
    },
  );
  const onError = useHandler(() => {
    reset();
  });
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction="row" gap="8px">
        <Controller
          name="url"
          control={control}
          rules={{
            validate: (value) => {
              if (value.trim() === "") {
                return t("validation.url.required");
              }
              if (!isValidDomain(value)) {
                return t("validation.url.invalidFormat");
              }
              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              autoFocus
              onBlur={(e) => {
                e.target.blur();
                handleSubmit(onSubmitHandler, onError)();
              }}
              error={!!error}
              helperText={error?.message}
              disabled={mutation.isLoading}
              slotProps={{
                htmlInput: {
                  maxLength: 2048,
                },
              }}
            />
          )}
        />
        {domainId ? <DeleteDomainButton domainId={domainId} /> : null}
      </Stack>
    </form>
  );
};

const Domains: React.FC = () => {
  const { channel } = ChannelQueriesContext.useData();
  const domains = useMemo(() => {
    if (channel.type !== "wccs") return [];
    return channel.channelInformation.domains;
  }, [channel.type, channel.channelInformation]);
  const handleAddDomain = useHandler(function handleAddDomain() {
    // TODO: add empty domain form
  });
  return (
    <Stack direction="column" gap="8px">
      {domains.map((domain) => (
        <DomainItemForm key={domain.id} domainId={domain.id} url={domain.url} />
      ))}
      <Button
        variant="transparent"
        onClick={() => handleAddDomain()}
        startIcon="+"
      >
        <Trans i18nKey="page.channel.install.domain.add.label" />
      </Button>
    </Stack>
  );
};

const codeStyle = defineStyles({
  code: css({
    backgroundColor: theme.colors.neutral010,
    padding: "8px 12px",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.neutral030}`,
  }),
});

const SelfHostedCode: React.FC = () => {
  const [code] = useState("TODO: add self hosted code");
  return (
    <Stack direction="column" gap="8px">
      <Typography css={codeStyle.code}>{code}</Typography>
      <CopyButton variant="plain" text={code} />
    </Stack>
  );
};

const EcHostedCode: React.FC = () => {
  const { t } = useTranslation();
  const [code] = useState("TODO: add ec hosted code");
  return (
    <Stack direction="column" gap="8px">
      <Typography>
        <Trans i18nKey="page.channel.wccs.install.sdk.ec.desc" />
      </Typography>
      <ExternalLink
        trailingIcon
        href={t("page.channel.wccs.install.sdk.howTo.link")}
      >
        <Trans i18nKey="page.channel.wccs.install.sdk.howTo.label" />
      </ExternalLink>
      <Typography css={codeStyle.code}>{code}</Typography>
      <CopyButton variant="plain" text={code} />
    </Stack>
  );
};

const selfHostedStyle = defineStyles({
  icon: css({
    fontSize: "16px",
    color: theme.colors.staticFgBody,
  }),
  howTo: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "12px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    border: `1px solid ${theme.colors.staticFgLine}`,
  }),
});

const SelfHosted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap="16px">
      <Stack direction="column" gap="8px">
        <Typography>
          <Trans i18nKey="page.channel.wccs.install.sdk.selfHosted.desc" />
        </Typography>
        <Typography>
          <ExternalLink
            trailingIcon
            href={t("page.channel.wccs.install.sdk.howTo.link")}
          >
            <Trans i18nKey="page.channel.wccs.install.sdk.howTo.label" />
          </ExternalLink>
        </Typography>
      </Stack>
      <SelfHostedCode />
      <div css={selfHostedStyle.howTo}>
        <Stack direction="row" gap="4px" alignItems="center">
          <MotifIcon un-i-motif="lightbulb" css={selfHostedStyle.icon} />
          <Typography fontWeight={500}>
            <Trans i18nKey="page.channel.wccs.install.sdk.tracking.title" />
          </Typography>
        </Stack>
        <Typography>
          <Trans i18nKey="page.channel.wccs.install.sdk.tracking.desc" />
        </Typography>
        <Stack direction="row" gap="16px">
          <ExternalLink
            trailingIcon
            href={t(
              "page.channel.wccs.install.sdk.tracking.helpingCenter.link",
            )}
          >
            <Trans i18nKey="page.channel.wccs.install.sdk.tracking.helpingCenter.label" />
          </ExternalLink>
          <ExternalLink
            trailingIcon
            href={t("page.channel.wccs.install.sdk.tracking.docs.link")}
          >
            <Trans i18nKey="page.channel.wccs.install.sdk.tracking.docs.label" />
          </ExternalLink>
        </Stack>
      </div>
    </Stack>
  );
};

const activationStyle = defineStyles({
  icon: css({
    fontSize: 16,
  }),
});

const Activation: React.FC<{ label: React.ReactNode; enabled: boolean }> = ({
  label,
  enabled,
}) => {
  return (
    <Alert
      severity={enabled ? "success" : "plain"}
      variant="outlined"
      icon={
        enabled ? (
          <MotifIcon un-i-motif="circle_check" css={activationStyle.icon} />
        ) : (
          <MotifIcon un-i-motif="circle_cross" css={activationStyle.icon} />
        )
      }
    >
      {label}
    </Alert>
  );
};

const EcTab = styled(Tab)({
  minWidth: "130px",
  minHeight: "auto",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  padding: "8px 12px",
  borderRadius: "4px",
  textTransform: "none",
  border: `1px solid ${theme.colors.neutral030}`,
  "&.Mui-selected": {
    border: `1px solid ${theme.colors.blue060}`,
    backgroundColor: theme.colors.blue010,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.colors.blue010,
    border: `1px solid ${theme.colors.blue060}`,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:focus": {
    backgroundColor: theme.colors.blue010,
    border: `1px solid ${theme.colors.blue060}`,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:active": {
    backgroundColor: theme.colors.blue010,
    border: `1px solid ${theme.colors.blue060}`,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:disabled": {
    backgroundColor: theme.colors.neutral020,
    border: `1px solid ${theme.colors.neutral030}`,
    color: theme.colors.neutral060,
  },
});

const EcTabs = styled(Tabs)({
  minHeight: "auto",
  "& .MuiTabs-flexContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(130px, 1fr))",
    gap: "8px",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const TabsSchema = z.enum([
  "selfHosted",
  "shopline",
  "nineOneApp",
  "shopify",
  "cyberbiz",
]);
type TabsSchemaType = z.infer<typeof TabsSchema>;

const ecTabsStyles = defineStyles({
  icon: css({
    fontSize: 16,
  }),
  logo: css({
    fontSize: 20,
  }),
});

const Step3: React.FC = () => {
  const [value, setValue] = useState<TabsSchemaType>(
    TabsSchema.Enum.selfHosted,
  );
  const handleChange = useHandler<
    React.ComponentProps<typeof Tabs>["onChange"]
  >((_, newValue) => {
    const result = TabsSchema.safeParse(newValue);
    if (!result.success) return;
    setValue(result.data);
  });
  return (
    <Stack direction="column" gap="8px">
      <EcTabs value={value} onChange={handleChange} variant="fullWidth">
        <EcTab
          value={TabsSchema.Enum.selfHosted}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon un-i-motif="api" css={ecTabsStyles.icon} />
              <Trans i18nKey="page.channel.wccs.install.sdk.selfHosted.label" />
            </Stack>
          }
        />
        <EcTab
          value={TabsSchema.Enum.shopline}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-shopline_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
        <EcTab
          value={TabsSchema.Enum.nineOneApp}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-nine_one_app_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
        <EcTab
          value={TabsSchema.Enum.shopify}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-shopify_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
        <EcTab
          value={TabsSchema.Enum.cyberbiz}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-cyberbiz_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
      </EcTabs>
      {value === TabsSchema.Enum.selfHosted && <SelfHosted />}
      {value !== TabsSchema.Enum.selfHosted && <EcHostedCode />}
    </Stack>
  );
};

const Installation: React.FC = () => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  if (channel.type !== "wccs") return null;
  return (
    <Stack gap="8px">
      <Typography
        variant="h2"
        fontWeight={700}
        color={theme.colors.staticFgTitle}
      >
        <Trans i18nKey="page.channel.install.title" />
      </Typography>
      <BadgeList>
        <BadgeItem
          index={1}
          title={<Trans i18nKey="page.channel.install.domain.title" />}
          content={
            <Stack direction="column" gap="8px">
              <Typography color={theme.colors.staticFgBody}>
                <Trans i18nKey="page.channel.install.domain.desc" />
              </Typography>
              <Domains />
            </Stack>
          }
        />
        <BadgeItem
          index={2}
          title={<Trans i18nKey="page.channel.install.activation.title" />}
          content={
            <Stack direction="column" gap="8px">
              <Typography>
                <Trans i18nKey="page.channel.install.activation.desc" />
              </Typography>
              <Typography fontWeight={500}>
                <Trans i18nKey="channels.wccs.activation.title" />
              </Typography>
              <Activation
                label={t("channels.wccs.activation.webChatModule")}
                enabled={channel.channelInformation.webTrackingEnabled}
              />
              <Activation
                label={t("channels.wccs.activation.webTracking")}
                enabled={channel.channelInformation.webTrackingEnabled}
              />
            </Stack>
          }
        />
        <BadgeItem
          index={3}
          title={<Trans i18nKey="page.channel.wccs.install.sdk.title" />}
          content={<Step3 />}
        />
        <BadgeItem
          index={4}
          title={<Trans i18nKey="page.channel.wccs.install.check.title" />}
          content={
            <>
              <Typography>
                <Trans i18nKey="page.channel.wccs.install.check.desc" />
              </Typography>
              <Typography>
                <ExternalLink
                  trailingIcon
                  href={t("page.channel.wccs.install.check.howTo.link")}
                >
                  <Trans i18nKey="page.channel.wccs.install.check.howTo.label" />
                </ExternalLink>
              </Typography>
            </>
          }
        />
      </BadgeList>
    </Stack>
  );
};

export { Installation };
