import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { JSX } from "react";

/**
 * In many cases you can use Motif icons as they are, but when interfacing with Button and others, this may be helpful to avoid repetitive styling (e.g. positioning)
 */
type AcceptedElementType = "div" | "span" | "i";

const defaultComponent = "div";
type DefaultComponent = typeof defaultComponent;

type OverridableComponent<TComponent extends React.ElementType> =
  React.ComponentPropsWithoutRef<TComponent> & {
    /** Decide what element to be rendered */
    component?: TComponent;
  };

type MotifIconProps<TComponent extends AcceptedElementType> =
  OverridableComponent<TComponent> & {
    "un-i-motif": React.ComponentProps<DefaultComponent>["un-i-motif"] & string;
  };

/**
 *
 * @example
 * ```tsx
 * <MotifIcon un-i-motif="paper_plane" /> // by default it renders a div
 * <MotifIcon un-i-motif="paper_plane" component="span" /> // renders a span
 * ```
 */
export const MotifIcon = forwardRef(
  <TComponent extends AcceptedElementType>(
    props: MotifIconProps<TComponent>,
    ref: React.Ref<React.ElementRef<TComponent>>,
  ) => {
    const { component: Component = defaultComponent, ...restProps } = props;

    return (
      <Component
        {...({ ...restProps } as unknown as JSX.LibraryManagedAttributes<
          TComponent,
          React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
          >
        >)}
        ref={ref}
      />
    );
  },
);
