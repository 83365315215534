import type { UseQueryResult } from "@tanstack/react-query";
import { mapValues } from "es-toolkit";
import type { FC, ReactNode } from "react";

import { createContext } from "../react/createContext";

type DataMap<QS extends Record<string, UseQueryResult>> = {
  [K in keyof QS]: Extract<
    QS[K],
    {
      isSuccess: true;
    }
  >["data"];
};

/**
 * Create a context to wrap multiple queries and provide the data from the
 * queries.
 *
 * ```tsx
 * // 1. Declare the queries
 * function useMyQueries() {
 *   const foo = useQuery(["b"], () => "str", {
 *     // 2. enable suspense and error boundary
 *     suspense: true,
 *     useErrorBoundary: true,
 *   });
 *   const bar = useQuery(["b"], () => 123, {
 *     suspense: true,
 *     useErrorBoundary: true,
 *   });
 *   const result = useMemo(() => ({ foo, bar }), [foo, bar]);
 *   return result;
 * }
 *
 * // 3. Create the context
 * const MyQueriesContext = createQueriesContext<ReturnType<typeof useMyQueries>>();
 *
 * function Wrapped() {
 *   const queries = useMyQueries();
 *   return (
 *     // 4. Wrap the provider with ErrorBoundary and Suspense
 *     <ErrorBoundary>
 *       <Suspense>
 *         <MyQueriesContext.Provider queries={queries}>
 *           <Content />
 *         </MyQueriesContext.Provider>
 *       </Suspense>
 *     </ErrorBoundary>
 *   );
 * }
 *
 * function Children() {
 *   // 5. Use the data from the context without error handling and suspense
 *   const data = MyQueriesContext.useData(); // { foo: "str", bar: 123 }
 * }
 * ```
 */
function createQueriesContext<QS extends Record<string, UseQueryResult>>() {
  const Context = createContext<QS>({
    name: "LoaderContext",
  });
  const QueriesProvider: FC<{ queries: QS; children: ReactNode }> = ({
    queries,
    children,
  }) => {
    if (Object.values(queries).some((query) => !query.isSuccess)) {
      return null;
    }
    return <Context.Provider value={queries}>{children}</Context.Provider>;
  };
  function useQueries() {
    const queries = Context.useContext();
    return queries;
  }
  function useQueriesData(): DataMap<QS> {
    const queries = useQueries();
    return mapValues(queries, (query) => query.data);
  }

  return {
    Provider: QueriesProvider,
    useQueries,
    useData: useQueriesData,
  };
}

export { createQueriesContext };
