import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import { uniqBy } from "es-toolkit/compat";
import { type FC, useMemo } from "react";

import { Trans } from "@/features/i18n/Trans";
import type { InterludeTypes } from "@/interlude";

export const UnifyScope: FC<{ orgs: Array<InterludeTypes["Org"]> }> = ({
  orgs,
}) => {
  const notMergingWithOtherOrgs = useMemo(
    function computeIsMergingWithOtherOrgs() {
      return uniqBy(orgs, "uuid").length === 1;
    },
    [orgs],
  );

  const scopes = useMemo(
    function computeScopes() {
      return orgs
        .map((org) => `${org.source.toUpperCase()} ${org.name}`)
        .join(", ");
    },
    [orgs],
  );

  if (notMergingWithOtherOrgs) return null;

  return (
    <Alert severity="warning" icon={<InfoOutlinedIcon />}>
      <Trans
        i18nKey="feature.profileUnification.warning"
        values={{
          scopes: scopes,
        }}
        components={{
          strong: <strong />,
        }}
      />
    </Alert>
  );
};
