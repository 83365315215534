import { Breadcrumbs, css, Stack } from "@mui/material";
import { theme } from "@polifonia/theme";
import useSwitch from "@react-hook/switch";
import { getRouteApi, Link } from "@tanstack/react-router";
import { useSnackbar } from "notistack";
import { type FC, Suspense } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { BarLoading } from "@/components/Loading/BarLoading";
import { Typography } from "@/components/Typography";
import { interlude } from "@/interlude";
import {
  ChannelQueriesContext,
  ChannelQueriesContextProvider,
} from "@/pages/channels/pages/detail/channelQueriesContext";
import { ChannelNameEdit } from "@/pages/channels/pages/detail/components/ChannelNameEdit";
import { DisconnectModal } from "@/pages/channels/pages/detail/whatsapp/components/DisconnectModal";
import { FailedConnectModal } from "@/pages/channels/pages/detail/whatsapp/components/FailedConnectModal";
import { PageTitle } from "@/pages/components/PageTitle";

const route = getRouteApi("/channels/$type/$id");

const WhatsappDetailPageInternal: FC = () => {
  const { t } = useTranslation();
  const { id } = route.useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [openFailedConnectModal, toggleFailedConnectModal] = useSwitch();
  const [openDisconnectModal, toggleDisconnectModal] = useSwitch();

  const { channel } = ChannelQueriesContext.useData();
  const mutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess() {
        toggleDisconnectModal.off();
        enqueueSnackbar(t("page.channel.whatsapp.disconnect.toast.success"), {
          variant: "success",
        });
      },
    },
  );

  const handleDisconnect = () => {
    mutation.mutate({
      status: "disconnected",
    });
  };

  return (
    <>
      <Stack gap="8px">
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Link to="/channels" css={css({ textDecoration: "none" })}>
            <Typography variant="body" color={theme.colors.staticFgBody}>
              {t("channels.title")}
            </Typography>
          </Link>
          <Link
            to="/channels/$type/$id"
            params={{
              type: "whatsapp",
              id,
            }}
            css={css({ textDecoration: "none" })}
          >
            <Typography variant="body" color={theme.colors.staticFgNote}>
              {t("common.whatsapp")}
            </Typography>
          </Link>
        </Breadcrumbs>
        <PageTitle>{t("page.channel.whatsapp.detail.title")}</PageTitle>
        <Stack gap="24px" css={css({ maxWidth: "512px" })}>
          <Stack gap="8px">
            <Typography
              variant="h3"
              fontWeight="medium"
              color={theme.colors.staticFgTitle}
            >
              {t("page.channel.whatsapp.detail.name")}
            </Typography>
            <ChannelNameEdit />
          </Stack>
          <Stack gap="8px">
            <Typography
              variant="h3"
              fontWeight="medium"
              color={theme.colors.staticFgTitle}
            >
              {t("page.channel.whatsapp.detail.token.title")}
            </Typography>
            <Typography variant="note" color={theme.colors.staticFgNote}>
              {t("page.channel.whatsapp.detail.token.description")}
            </Typography>
            <Button variant="plain">Reauthorize</Button>
          </Stack>
          <Stack gap="8px">
            <Typography
              variant="h3"
              fontWeight="medium"
              color={theme.colors.staticFgTitle}
            >
              {t("page.channel.whatsapp.detail.connection.title")}
            </Typography>
            <Typography variant="note" color={theme.colors.staticFgNote}>
              {t("page.channel.whatsapp.detail.connection.description")}
            </Typography>
            <Button
              variant="plain"
              color="error"
              onClick={toggleDisconnectModal.on}
              disabled={channel.status === "disconnected"}
            >
              {t("common.disconnect")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <FailedConnectModal
        open={openFailedConnectModal}
        onClose={toggleFailedConnectModal.off}
        onConfrim={toggleFailedConnectModal.off}
      />
      <DisconnectModal
        open={openDisconnectModal}
        isLoading={mutation.isLoading}
        onClose={toggleDisconnectModal.off}
        onConfrim={handleDisconnect}
      />
    </>
  );
};

export const WhatsappDetailPage: React.FC = () => {
  return (
    <Suspense fallback={<BarLoading />}>
      <ChannelQueriesContextProvider>
        <WhatsappDetailPageInternal />
      </ChannelQueriesContextProvider>
    </Suspense>
  );
};
