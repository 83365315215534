import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports -- this is the only place we import from @mui/material
import type { ButtonProps as MuiButtonProps } from "@mui/material/Button";
// eslint-disable-next-line no-restricted-imports -- this is the only place we import from @mui/material
import MuiButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { theme } from "@polifonia/theme";
import type { HtmlDataAttributes } from "@polifonia/utils/react/HtmlDataAttributes";
import dayjs from "dayjs";
import { noop } from "es-toolkit";
import type { CSSProperties } from "react";
import { useEffect, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import { NewWindowIcon } from "@/components/Icons/NewWindowIcon";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "plain"
  | "transparent"
  | "danger";

const seed = random();
const cssClickCountDownProgressColorVariableName = `--click-count-down-progress-color-${seed}`;
const cssClickCountDownProgressVariableName = `--click-count-down-progress-${seed}`;
const cssClickCountDownProgressing = css`
  background-image: linear-gradient(
    to right,
    var(${cssClickCountDownProgressColorVariableName})
      var(${cssClickCountDownProgressVariableName}),
    transparent var(${cssClickCountDownProgressVariableName})
  );
  cursor: progress;
`;

const cssButtonOverride = css`
  text-transform: none;
  font-weight: 400;
`;

const cssButtonVariant: Record<ButtonVariant, SerializedStyles> = {
  primary: css`
    background-color: ${theme.colors.buttonPrimaryPrimaryDefault};
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.buttonNamePrimaryHovered};
    }
    &:active {
      background-color: ${theme.colors.buttonNamePrimaryActive};
    }
  `,
  secondary: css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.buttonPrimaryPrimaryDefault};
    border: 1px solid ${theme.colors.buttonPrimaryPrimaryDefault};
    &:hover {
      background-color: ${theme.colors.white};
    }
    &:active {
      color: ${theme.colors.buttonNamePrimaryActive};
      border: 1px solid ${theme.colors.buttonNamePrimaryActive};
    }
  `,
  plain: css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.buttonPlainDefault};
    border: 1px solid ${theme.colors.neutral050};
    &:hover {
      background-color: ${theme.colors.white};
      color: ${theme.colors.buttonPlainHover};
      border: 1px solid ${theme.colors.buttonPlainHover};
    }
    &:active {
      color: ${theme.colors.buttonPlainActive};
      border: 1px solid ${theme.colors.buttonNamePrimaryActive};
    }
    :disabled {
      background-color: ${theme.colors.neutral010};
    }
  `,
  transparent: css`
    background-color: transparent;
    color: ${theme.colors.buttonPrimaryPrimaryDefault};
    &:hover {
      background-color: transparent;
      color: ${theme.colors.buttonNamePrimaryHovered};
    }
    &:active {
      background-color: transparent;
      color: ${theme.colors.buttonNamePrimaryActive};
    }
  `,
  danger: css`
    background-color: ${theme.colors.red060};
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.red050};
    }
    &:active {
      background-color: ${theme.colors.red070};
    }
  `,
};

const cssButtonDisabled: Partial<Record<ButtonVariant, SerializedStyles>> = {
  primary: css`
    cursor: not-allowed;
    background-color: ${theme.colors.buttonNamePrimaryDisabled};
  `,
  danger: css`
    background-color: ${theme.colors.buttonNamePrimaryDisabled};
  `,
};

const cssButtonLoading = css`
  pointer-events: none;
  opacity: 0.65;
`;

const cssButtonWrapper = css`
  position: relative;
  display: inline-block;
  width: fit-content;
`;

const cssDisabledButtonOverlay = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
`;

export interface ButtonProps
  extends Omit<MuiButtonProps, "variant">,
    HtmlDataAttributes {
  variant?: ButtonVariant;
  loading?: boolean;
  clickCountDownMs?: number;
  clickCountDownColor?: string;
}

function useCountDownController() {
  const countDownController = useMemo(function setupCountDownController() {
    type CountDown = {
      abort: () => void;
      startDate: Date;
      endDate: Date;
      /**
       * From 100 to 0.
       */
      progress: number;
    };
    const useStore = createWithEqualityFn<{
      countDown: CountDown | null;
    }>(() => ({
      countDown: null,
    }));
    function clear() {
      const countDown = useStore.getState().countDown;
      if (!countDown) return;
      useStore.setState({
        countDown: null,
      });
      countDown.abort();
    }
    function start({ countDownMs }: { countDownMs: number }) {
      clear();
      return new Promise<void>((resolve) => {
        const timeout = setTimeout(function countDown() {
          resolve();
          clear();
        }, countDownMs);
        function abort() {
          clearTimeout(timeout);
          clear();
        }
        const now = dayjs();
        useStore.setState({
          countDown: {
            startDate: now.toDate(),
            endDate: now.millisecond(countDownMs).toDate(),
            progress: 100,
            abort,
          },
        });
      });
    }
    const cleanUpTasks: Array<() => void> = [];
    let cancelLastUpdateProgress: () => void = noop;
    cleanUpTasks.push(
      useStore.subscribe(function updateProgress(state) {
        if (!state) return;
        cancelLastUpdateProgress();
        let canceled = false;
        cancelLastUpdateProgress = function cancelLastUpdateProgress() {
          canceled = true;
        };
        function updateProgress() {
          if (canceled) return;
          const { countDown } = useStore.getState();
          if (!countDown) return;
          const currentProgress = countDown.progress;
          const nextProgress =
            100 -
            Math.max(
              Math.min(
                100,
                ((Date.now() - countDown.startDate.getTime()) /
                  (countDown.endDate.getTime() -
                    countDown.startDate.getTime())) *
                  100,
              ),
              0,
            );
          if (nextProgress !== currentProgress) {
            useStore.setState((state) => {
              if (!state.countDown) return {};
              return {
                countDown: {
                  ...state.countDown,
                  progress: nextProgress,
                },
              };
            });
          }
          requestAnimationFrame(updateProgress);
        }
        updateProgress();
      }),
    );
    function useCleanup() {
      useEffect(function cleanupOnUnMount() {
        return function cleanup() {
          cancelLastUpdateProgress();
          clear();
          for (const task of cleanUpTasks) {
            task();
          }
        };
      }, []);
    }

    function useIsCounting() {
      const countDown = useStore((state) => state.countDown);
      return Boolean(countDown);
    }

    return {
      useStore,
      start,
      clear,
      useCleanup,
      useIsCounting,
    };
  }, []);
  countDownController.useCleanup();
  return countDownController;
}

export const Button = fc<ButtonProps>(function Button({
  variant = "primary",
  disabled = false,
  loading = false,
  href = "",
  style,
  clickCountDownMs = 0,
  clickCountDownColor,
  onClick,
  ...restProps
}) {
  const buttonVariantStyle = cssButtonVariant[variant];
  const buttonDisabledStyle = cssButtonDisabled[variant];

  const externalLinkProps = href?.startsWith("http")
    ? {
        href,
        target: "_blank",
        endIcon: <NewWindowIcon />,
      }
    : { href };

  const countDownController = useCountDownController();
  const countDownState = countDownController.useStore(
    (state) => state.countDown,
  );
  const isCountingDown = countDownController.useIsCounting();

  const onClickWithCountDown = useHandler<ButtonProps["onClick"]>(
    async function onClickWithCountDown(...args) {
      if (countDownController.useStore.getState().countDown) return;
      await countDownController.start({
        countDownMs: clickCountDownMs,
      });
      onClick?.(...args);
    },
  );

  const resolvedStyle = useMemo<CSSProperties>(() => {
    return {
      ...style,
      ...(!clickCountDownMs
        ? null
        : (function getStaticButtonStyle(): ButtonProps["style"] {
            if (!clickCountDownMs) return {};
            const _clickCountDownColor =
              clickCountDownColor ??
              (variant === "danger"
                ? theme.colors.red070
                : theme.colors.buttonNamePrimaryActive);
            return {
              [cssClickCountDownProgressColorVariableName]:
                _clickCountDownColor,
              [cssClickCountDownProgressVariableName]: `${countDownState?.progress ?? 0}%`,
            };
          })()),
    };
  }, [
    clickCountDownMs,
    countDownState?.progress,
    clickCountDownColor,
    style,
    variant,
  ]);

  return (
    <span css={cssButtonWrapper}>
      <MuiButton
        variant={["secondary", "plain"].includes(variant) ? "outlined" : "text"}
        sx={{
          "&.MuiButton-startIcon": {
            marginLeft: 0,
          },
          ...(["primary", "danger"].includes(variant) && {
            "&.MuiButton-text.Mui-disabled": {
              color: theme.colors.white,
            },
          }),
        }}
        style={resolvedStyle}
        css={[
          cssButtonOverride,
          restProps.color ? null : buttonVariantStyle,
          ...(disabled ? [buttonDisabledStyle] : []),
          ...(loading ? [cssButtonLoading] : []),
          isCountingDown && cssClickCountDownProgressing,
        ]}
        onClick={clickCountDownMs ? onClickWithCountDown : onClick}
        disabled={disabled}
        startIcon={
          loading ? (
            <CircularProgress
              disableShrink
              size="0.75em"
              color="inherit"
              thickness={3}
            />
          ) : (
            restProps.startIcon
          )
        }
        {...externalLinkProps}
        {...restProps}
      />
      {disabled ? <span css={cssDisabledButtonOverlay}></span> : null}
    </span>
  );
});
