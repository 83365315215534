import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { Overwrite } from "@mui/types";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";

type ButtonProps = React.ComponentProps<typeof Button>;

interface OwnProps {
  /**
   * The text to copy to clipboard
   */
  text: string;
}

type Props = Overwrite<Omit<ButtonProps, "onClick">, OwnProps>;

/**
 * A button that copies text on click, displays a success state, and optionally
 * allows a custom label; otherwise, it defaults to the “Copy” translation.
 */
export const CopyButton: React.FC<Props> = ({ text, children, ...props }) => {
  const { t } = useTranslation();
  const timer = useRef<number>();
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const doCopy = useHandler<ButtonProps["onClick"]>(
    async function doCopy(event) {
      event.stopPropagation();
      if (copied) return;

      copy(text);

      setCopied(true);
      enqueueSnackbar(t("component.button.copy.success"), {
        variant: "success",
      });
      timer.current = window.setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
  );

  return (
    <Button
      startIcon={
        copied ? (
          <MotifIcon un-i-motif="check" />
        ) : (
          <MotifIcon un-i-motif="copy" />
        )
      }
      onClick={doCopy}
      {...props}
    >
      {!children ? t("component.button.copy") : children}
    </Button>
  );
};
